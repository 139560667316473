:root {
	--primary: #083359;
	--secondary: #6c757d;
	--tertiary: rgb(212, 212, 212);
	--dark: #292b2c;
	--light: #f2f2f2;
	--shadow: #023759;
}

html,
body {
	color: var(--dark);
	background-color: var(--light);
	font-family: 'Cuprum', sans-serif;
	color: var(--dark);
	overflow-x: hidden;
}

h1 {
	font-size: 5.7rem;

	/* padding-bottom: 1rem; */
	filter: drop-shadow(2px 1px 0.5px #858585);
}
h2 {
	font-size: 4.5rem;
	filter: drop-shadow(2px 1px 0.5px #858585);
}

h3 {
	font-size: 4rem;

	filter: drop-shadow(2px 1px 0.5px #858585);
}

h4 {
	font-size: 2.5rem;
	font-family: 'Cuprum', sans-serif;
	filter: drop-shadow(2px 1px 0.5px #858585);
}

h5 {
	font-size: 1.7rem;

	filter: drop-shadow(2px 1px 0.5px #858585);
}

h6 {
	font-size: 1.5rem;
	font-weight: 300;
	color: var(--dark);
	filter: drop-shadow(2px 1px 0.5px #858585);
}

p {
	font-size: 1.25rem;
	font-weight: 200;
	/* filter: drop-shadow(2px 2px 4px #121212); */
}

/* b {
	color: var(--tertiary);
	filter: drop-shadow(3px 2px 3px #000000);
} */
/* span {
	filter: drop-shadow(3px 2px 3px #000000);
} */

.brand {
	font-family: 'Righteous', Arial, Helvetica, sans-serif;
	font-size: 2.25rem;
	font-weight: 400;
	font-style: normal;
	filter: drop-shadow(2px 1px 0.5px #747373);
}

/* .icon {
	color: var(--tertiary);
} */

/* NAVBAR STYLES */

/* .navbar-dark {
	background-color: var(--primary) !important;
	filter: drop-shadow(3px 2px 15px #1a1a1a);
	z-index: 999;
}

.navbar-brand {
	font-family: marydale, sans-serif;
	font-size: xx-large;
	font-weight: 700;
	font-style: normal;
	filter: drop-shadow(3px 2px 3px #121212);
}

.camel-A {
	font-size: 2rem;
}

nav ul li {
	text-transform: uppercase;
	font-weight: 300;
	font-size: 1.3rem;
	letter-spacing: 0.2rem;
	filter: drop-shadow(3px 2px 3px #121212);
}

a.nav-link:active,
a.nav-link:focus {
	border-bottom: 0.075rem solid var(--tertiary) !important;
}

.dropdown-menu {
	background: linear-gradient(0deg, #0248735d, #083359a8);
}

.dropdown-item {
	color: var(--tertiary) !important;
	filter: drop-shadow(0.25rem 0.188rem 0.25rem #1a1a1a);
} */

/* HERO */

.hero {
	position: relative;
	background: linear-gradient(
			0deg,
			rgba(58, 65, 70, 0.133),
			rgba(46, 46, 75, 0.332)
		),
		url(../src/assets/img/codeBackground2.png);
	background-size: cover;
	background-position: center;
	height: 100vh;
}

.hero-bg {
	position: relative;
	background: linear-gradient(
			0deg,
			rgba(58, 65, 70, 0.133),
			rgba(46, 46, 75, 0.332)
		),
		url(../src/assets/img/codeBackground2.png);
	background-size: cover;
	background-position: center;
	height: 100vh;
}

.blur {
	background: #5888a64e;
	backdrop-filter: blur(3.5px);
	height: 100vh;
}

.headshot {
	z-index: 1;
}

.hero-heading {
	font-family: 'Righteous', Arial, Helvetica, sans-serif;

	/* font-style: regular; */
	font-weight: 400;
	/* font-size: 5rem; */
}

.hero-sub-heading {
	font-family: 'Marvel', sans-serif;

	font-weight: 300;
}

.typed-text {
	font-family: 'Source Code Pro', light, monospace;
	/* font-style: light; */
	font-weight: 300;
	font-size: 1.5rem;
	color: var(--tertiary) !important;
	/* filter: drop-shadow(3px 2px 3px #464545);  */
}

/* hero button ***************/

/* .btn-main-offer {
	background-color: var(--primary);
	color: var(--tertiary);
	text-transform: uppercase;
	font-size: 1.1rem;
	border-radius: 0;
	padding: 10px 12px 12px 12px;
	z-index: 3;
}

.btn-main-offer:hover {
	text-decoration: none;
} */

/* ******* aboutMe Section******** */

.skills {
	text-align: center;
	padding-bottom: 2rem;
	color: var(--dark);
}

.skills .circle {
	position: relative;
	margin: 0.375rem auto;
	background: white;
	border-radius: 50%;
	width: 3.125rem;
	height: 3.125rem;
	filter: drop-shadow(3px 2px 2px #000000);
}

.skills .icon {
	font-size: 2rem;
	color: var(--dark);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.skills .skill-text {
	color: var(--light);
	filter: drop-shadow(2px 1.5px 2px #292929);
}

.skills .box {
	height: 7rem;
	padding: 0.625rem;
	margin-bottom: 1.875rem;
	/* background: rgb(202, 202, 202); */
	background: var(--dark);
	transition: 0.3s ease-in-out;
}

.skills .box:hover {
	background: var(--light);
}

.skills .box:hover .icon {
	color: var(--light);
}

.skills .box:hover .svg-icon {
	color: var(--light);
}

.skills .box:hover .circle {
	background: var(--dark);
}

.skills .box:hover .skill-text {
	color: var(--dark);
	filter: drop-shadow(2px 1.5px 2px #292929);
}

/* ********MY WORK********** */

. .overflow {
	position: absolute;
	opacity: 0;
	background: var(--dark);
	width: 15rem;
	height: 100%;
	top: 0;
	border: 1px solid var(--dark);
	cursor: pointer;
}

.project-img-box:hover .overflow {
	opacity: 60%;
	transition: 0.3s ease-in-out;
}

.project-icon {
	position: absolute;
	opacity: 0;
	margin-left: -8rem;
	margin-top: 3.6rem;
	font-size: 1.5rem;
	color: var(--tertiary);
	filter: drop-shadow(3px 2px 3px #616161);
}

.project-icon:hover {
	opacity: 70%;
	transition: 0.3s ease-in-out;
	cursor: pointer;
}

.project-image-popupbox {
	width: 45rem;
	padding: 0 0.5rem;
}

.popupbox-wrapper {
	margin-top: 7rem;
}

.popupbox-overlay {
	background: rgba(37, 37, 37, 0.215) !important;
}

.popupbox-titleBar {
	background-color: var(--light);
	margin-top: 0rem;
}

.popupbox-content {
	background-color: var(--light);
}

.hyper-link {
	filter: drop-shadow(3px 2px 3px #646464);
	/* color: var(--tertiary); */
}

.hyper-link:hover {
	/* color: var(--light); */
}

.resume {
	color: var(--tertiary);
}

.copyright {
	font-weight: 200;
}

.footer-dark-bottom {
	background-color: var(--primary) !important;
}

/* SCROLLtoTOP */
/* .move-up {
	position: fixed;
	margin-right: 50px;
	cursor: pointer;
	bottom: 30px;
	right: 0;
	z-index: 1;
	text-align: center;
	height: 45px;
	line-height: 46px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
} */

/********** MEDIA Q ********/

@media (max-width: 768px) {
	.navbar-brand {
		font-weight: 100;
		margin: 0 !important;
		font-size: 1.75rem;
		filter: drop-shadow(3px 2px 2px #121212);
	}
}

/* Hero text */
@media (max-width: 768px) {
	/* .heading h1 {
		font-size: 2rem !important;
		padding: 0;
	}

	.heading h2 {
		font-size: 1.1rem !important;
	}
	.typed-text {
		font-size: 1.3rem !important;
	} */
}

@media (max-width: 768px) {
	/* .project-image-popupbox {
		width: 100%;
	} */
}

@media (max-height: 570px) {
	/* .popupbox-wrapper {
		height: 100%;
	}
	.project-image-popupbox {
		width: 50%;
	} */
}

@media (max-height: 280px) {
	/* .popupbox-wrapper {
		height: 90%;
	}
	.project-image-popupbox {
		width: 30%;
	} */
}

/* fonts */
/*
font-family: 'Cuprum', sans-serif;
font-family: 'Marvel', sans-serif;
font-family: 'Monda', sans-serif;
font-family: 'Press Start 2P', cursive;
font-family: 'Righteous', cursive;
font-family: 'Saira Extra Condensed', sans-serif;
font-family: 'Source Code Pro', monospace;
*/
